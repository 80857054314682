<template>
  <el-dialog
    class="loginBox"
    :visible.sync="visible"
    width="780px"
    :append-to-body="true"
    :show-close="false"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    :custom-class="'loginContainer'"
    @open="onDialogOpen"
    @closed="handleClose"
    :destroy-on-close="true"
  >
    <div class="loginMain">
      <i class="el-icon-close closeIcon" @click="handleClickClose"></i>
      <div class="mainLeft"></div>
      <div class="mainRight" v-if="type === 0">
        <div class="rightTitle">
          <span class="wxIcon"></span>
          微信扫码登录注册
        </div>
        <div class="qrCodeWrapper">
          <div class="loadingBox" v-if="isLoading">
            <!--            <img src="@/assets/img/qr_code_loading.jpg" alt="" />-->
          </div>
          <div class="qrcodeContentBox" v-else>
            <img v-if="qrCodeStatus === QR_CODE_STATUS.SUCCESS" :src="loginQrCodeUrl" alt="" style="width: 100%" />
            <div class="qrCodeStatusBox" v-else>
              <div class="qrCodeStatusContent">
                <span class="refreshBtn" @click="onRefreshQrCode"></span>
                <span class="txt">{{ qrCodeStatus === QR_CODE_STATUS.EXPIRE ? '二维码已过期' : '获取失败' }}</span>
              </div>
            </div>
          </div>
        </div>
        <p class="tips">微信扫一扫关注公众号登录，1步极速注册登录</p>
        <div @click="changeLoginType(1)" class="changeBtn changePhoneBtn">手机号登录</div>
      </div>
      <div class="mainRight loginPhone" v-if="type === 1">
        <div class="rightTitle">手机号登录注册</div>
        <el-form :model="ruleForm" ref="form" @submit.native.prevent class="formBox">
          <el-form-item prop="phoneNumber">
            <div class="modal_item">
              <el-input v-model="ruleForm.phoneNumber" autocomplete="off" placeholder="请输入手机号码"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="code">
            <div class="code_item">
              <el-input v-model="ruleForm.code" autocomplete="off" placeholder="请输入短信中的验证码"></el-input>
              <button
                type="button"
                :class="{ getCodeBtn: true, disabled: isCountDown }"
                :disabled="isCountDown"
                @click="getCode"
              >
                {{ btnTxt }}
              </button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="handleLoginByPhoneNumber" class="loginBtn"
              >登录/注册</el-button
            >
          </el-form-item>
        </el-form>
        <div @click="changeLoginType(0)" class="changeBtn">微信登录</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { setToken } from '@/utils/token'
import { mapActions, mapState } from 'vuex'
import { checkPhoneNumberLoginStatus, getBindCode, getLoginQrCode, getLoginStatus, getMobileCode } from '@/api'
import { Message } from 'element-ui'
import { trackLogin } from '@/utils/track'
import { SOFT_CHANNEL, SOFT_CODE } from '@/config'

const QR_CODE_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  EXPIRE: 'expire'
}
export default {
  name: 'LoginModal',
  data() {
    return {
      QR_CODE_STATUS,
      qrCodeStatus: QR_CODE_STATUS.PENDING,
      loginQrCodeUrl: '',
      isLoading: false,
      checkLoginStatusTimer: null,
      visible: false,
      afterClose() {},
      onCancel() {},
      onSuccess() {},
      type: 0, // 0: 微信登录 1: 手机号登录
      timer: null,
      isCountDown: false,
      seconds: 60,
      ruleForm: {
        phoneNumber: '',
        code: ''
      }
    }
  },
  computed: {
    ...mapState(['isLoginModalVisible']),
    btnTxt() {
      return this.isCountDown ? this.seconds + 'S后获取' : '获取验证码'
    }
  },
  methods: {
    ...mapActions(['setUserInfo']),
    handleClickClose() {
      this.$Msg({
        showLeft: true,
        showRight: true,
        leftBtnTxt: '退出',
        rightBtnTxt: '继续登录',
        title: '温馨提示',
        content: `确认退出吗？微信扫码登录即可享限时活动哦～`,
        onClose: () => {},
        onClickLeftBtn: () => {},
        onClickRightBtn: () => {
          this.$Login()
        }
      })
      this.onCancel()
      this.hide()
    },
    handleClose() {
      this.afterClose()
      clearTimeout(this.checkLoginStatusTimer)
    },
    hide() {
      this.visible = false
    },
    loopCheckLoginStatus(ticket, expire_seconds, startTime) {
      clearTimeout(this.checkLoginStatusTimer)
      let currentTime = new Date().getTime()
      if ((currentTime - startTime) / 1000 > expire_seconds) {
        // TODO: 二维码已过期
        this.qrCodeStatus = QR_CODE_STATUS.EXPIRE
        return
      }
      this.checkLoginStatusTimer = setTimeout(async () => {
        let r = await getLoginStatus(ticket)
        if (r.data.status === 0) {
          // TODO: 更新token，更新用户信息
          setToken(r.data.data)
          await this.setUserInfo(r.data.data)
          await this.$store.dispatch('updateUserInfo')
          trackLogin()
          this.hide()
          Message({
            type: 'success',
            message: '登录成功！'
          })

          clearTimeout(this.checkLoginStatusTimer)
          localStorage.setItem('open_c', '0')
          if (typeof this.onSuccess === 'function') {
            this.onSuccess()
          }
        } else {
          this.loopCheckLoginStatus(ticket, expire_seconds, startTime)
        }
      }, 1500)
    },
    async handleGetLoginQrCode() {
      this.isLoading = true
      try {
        let res = await getLoginQrCode()
        if (res.data.status === 0) {
          let { ticket, expire_seconds } = res.data.data
          if (ticket) {
            // let src = await this.toQRCode(url)
            let src = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticket}`
            let startTime = new Date().getTime()
            // 二维码获取成功
            this.qrCodeStatus = QR_CODE_STATUS.SUCCESS
            this.loginQrCodeUrl = src
            this.loopCheckLoginStatus(ticket, expire_seconds, startTime)
          } else {
            // 获取二维码失败
            this.qrCodeStatus = QR_CODE_STATUS.FAIL
          }
        } else {
          // 获取二维码失败
          this.qrCodeStatus = QR_CODE_STATUS.FAIL
        }
      } catch (e) {
        this.qrCodeStatus = QR_CODE_STATUS.FAIL
      } finally {
        this.isLoading = false
      }
    },
    async onRefreshQrCode() {
      await this.handleGetLoginQrCode()
    },
    // 当弹窗打开的时候调用
    async onDialogOpen() {
      await this.handleGetLoginQrCode()
    },
    validatePhoneNumber(str) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      return reg.test(str)
    },
    checkPhoneNumber() {
      if (this.ruleForm.phoneNumber.trim().length === 0) {
        Message.error('请输入手机号！')
        return false
      }
      let checkStatus = this.validatePhoneNumber(this.ruleForm.phoneNumber.trim())
      if (!checkStatus) {
        Message.error('请输入正确格式的手机号！')
        return false
      }
      return true
    },
    checkCode() {
      if (this.ruleForm.code.trim().length === 0) {
        Message.error('请输入验证码！')
        return false
      }
      return true
    },
    async getCode() {
      if (!this.isCountDown) {
        // TODO 校验手机号
        let flag = this.checkPhoneNumber()
        if (!flag) {
          return
        }

        this.seconds = 60
        this.isCountDown = true
        this.timer = setInterval(() => {
          if (this.seconds <= 0) {
            clearInterval(this.timer)
            this.seconds = 0
            this.isCountDown = false
            return
          }
          this.seconds--
        }, 1000)

        let res = await getMobileCode(this.ruleForm.phoneNumber.trim())
        if (res.data.status !== 0) {
          Message.error('验证发送失败！')
          return
        }
      }
    },
    /**
     * 切换登录方式
     * @param type
     * @returns {Promise<void>}
     */
    async changeLoginType(type) {
      this.type = type
      if (type === 0) {
        clearTimeout(this.timer)
        this.$refs.form.resetFields()
        this.isCountDown = false
        this.seconds = 60
        this.ruleForm = {
          phoneNumber: '',
          code: ''
        }
        await this.handleGetLoginQrCode()
      } else {
        clearTimeout(this.checkLoginStatusTimer)
      }
    },
    async handleLoginByPhoneNumber() {
      if (this.checkPhoneNumber() && this.checkCode()) {
        let res = await checkPhoneNumberLoginStatus({
          smscode: this.ruleForm.code,
          mobile_phone: this.ruleForm.phoneNumber,
          soft_channel: SOFT_CHANNEL,
          code: window?.getAppRuntime?.platform === 'electron' ? window?.getAppRuntime?.softCode : SOFT_CODE
        })
        if (res.data.status === 0) {
          // TODO: 更新token，更新用户信息
          setToken(res.data.data)
          await this.setUserInfo(res.data.data)
          await this.$store.dispatch('updateUserInfo')
          trackLogin()
          this.hide()
          Message({
            type: 'success',
            message: '登录成功！'
          })
          localStorage.setItem('open_c', '0')
          if (typeof this.onSuccess === 'function') {
            this.onSuccess()
          }
        } else {
          if (res.data.status === 1001) {
            Message({
              type: 'error',
              message: '验证码错误！'
            })
          } else {
            Message({
              type: 'error',
              message: '登录失败！'
            })
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
@import url('./main.less');
</style>
